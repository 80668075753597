<template>
  <div>
    <a-row gutter="12">
      <a-col :span="12">
        <a-select v-model="currentWarehouse" placeholder="仓库" :loading="warehouseLoading" :disabled="warehouseLoading"
          style="width: 100%;" @change="onChangeWarehouse">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="12">
        <a-select v-model="reservoirArea" placeholder="库区" :loading="reservoirAreaLoading"
          :disabled="reservoirAreaLoading" style="width: 100%;" @change="onChangereservoirArea">
          <a-select-option v-for="item in reservoirAreaItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import { warehouseOption, areaOption } from '@/api/option';

  export default {
    props: ['reservoirArea', 'warehouse'],
    model: { prop: 'reservoirArea', event: 'change' },
    data() {
      return {
        warehouseItems: [],
        reservoirAreaItems: [],
        warehouseLoading: false,
        reservoirAreaLoading: false,
        currentWarehouse: undefined,
      }
    },
    computed: {
      globalWarehouse() {
        return this.$store.state.user.globalWarehouse
      },
    },
    methods: {
      initData() {
        this.currentWarehouse = this.warehouse ? this.warehouse : this.globalWarehouse;
        this.getWarehouseOption();
        if (this.currentWarehouse) {
          this.getReservoirAreaOption();
        }
      },
      getWarehouseOption() {
        this.warehouseLoading = true;
        warehouseOption({ page_size: 999999 }).then(data => {
          this.warehouseItems = data.results;
        }).finally(() => {
          this.warehouseLoading = false;
        });
      },
      getReservoirAreaOption() {
        this.reservoirAreaLoading = true;
        areaOption({ page_size: 999999, warehouse: this.currentWarehouse }).then(data => {
          this.reservoirAreaItems = data.results;
          let index = this.reservoirAreaItems.findIndex(item => item.id == this.reservoirArea);
          if (index == -1 && this.reservoirAreaItems.length > 0) {
            this.onChangereservoirArea(this.reservoirAreaItems[0].id);
          }
        }).finally(() => {
          this.reservoirAreaLoading = false;
        });
      },
      onChangeWarehouse() {
        this.getReservoirAreaOption();
      },
      onChangereservoirArea(value) {
        this.$emit('change', value);
      },
    },
    mounted() {
      this.initData();
    },
    watch: {
      warehouse(value) {
        this.initData();

        // if (this.warehouseItems.length == 0) {
        //   this.initData();
          // this.currentWarehouse = value ? value : this.globalWarehouse;
          // if (this.currentWarehouse) {
            // this.getReservoirAreaOption();
          // }
        // }
      },
    },
  }
</script>